import React from "react"
import css from "./contact-info.module.scss"
const ContactInfo = () => (
  <div className={css.contact}>
    <div className={css.container}>
      <div className={css.contact__item}>
        <div className={css.country}>United States</div>
        <div className={css.info}>Blend-Divine Private Limited</div>
        <div className={css.info}>Apt # 407</div>
        <div className={css.info}>995 Southern Artery</div>
        <div className={css.info}>Quincy Massachusetts 02169-8407</div>
        <div className={css.info}>United States</div>
        <div className={css.email}>Email: info@blenddivine.com</div>
        {/* <div className={css.phone}>Phone: xxxxxxxxxx</div> */}
      </div>
      <div className={css.contact__item}>
        <div className={css.country}>Delhi, India</div>
        <div className={css.info}>Blend-Divine Private Limited</div>
        <div className={css.info}>102 Charmwood Plaza</div>
        <div className={css.info}>Charmwood Village</div>
        <div className={css.info}>Faridabad</div>
        <div className={css.info}>Haryana - 121009</div>
        <div className={css.email}>Email: info@blenddivine.com</div>
        <div className={css.phone}>Phone: +91 129-4889955</div>
      </div>
      <div className={css.contact__item}>
        <div className={css.country}>Mumbai, India</div>
        <div className={css.info}>Blend-Divine Private Limited</div>
        <div className={css.info}>Shop No. 1 &amp; 2 Sector 9 E,</div>
        <div className={css.info}>Opp. D- Mart</div>
        <div className={css.info}>Airoli, Navi Mumbai</div>
        <div className={css.info}>Maharastra - 400708</div>
        <div className={css.email}>Email: info@blenddivine.com</div>
        {/* <div className={css.phone}>Phone: +91 129-4889955</div> */}
      </div>
    </div>
  </div>
)

export default ContactInfo
