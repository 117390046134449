import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"

import HeroInner from "../components/HeroInner/hero-inner"
import ContactInfo from "../components/ContactInfo/contact-info"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <HeroInner
      title="Contact Us"
      imageAlt="Contact Us"
      imagePath="contact-us-hero.jpg"
    />
    <ContactInfo/>
  </Layout>
)

export default IndexPage
